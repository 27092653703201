<template>
  <div class="home">
    <Vcode :show="true" @success="onSuccess" @close="onClose"/>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Vcode from "vue3-puzzle-vcode";
import axios from "axios";

@Options({
  components: {Vcode},
})
export default class HomeView extends Vue {
  website: string[] = [];

  created() {
    axios.get("website.json").then((response) => {
      this.website = response.data;
    });
  }

  onSuccess() {
    console.log('成功');
    location.href = 'https://' + this.website[Math.floor(Math.random() * this.website.length)];
  }

  onClose() {
    console.log('失败');
  }
}
</script>
